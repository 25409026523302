import React from "react";

const HomeCtaSection = ({}:{}) => {
  return <div className="section-cta">
      <div className="uk-container-expand">
          <div className="section-cta__box">
              <div className="section-cta__img"><img src="/assets/img/img-cta.png" alt=""/></div>
              <div className="section-cta__title"><span>Nous sommes à votre entière <br/>disposition pour tout besoin d'engin </span>
              </div>
              <div className="section-cta__support">
                  <div className="support"><a className="support__link" href="tel:+22389890357">
                      <div className="support__icon"><i className="fas fa-headset"></i></div>
                      <div className="support__desc">
                          <div className="support__label">Appelez-nous</div>
                          <div className="support__phone">(+223)89 89 03 57</div>
                      </div>
                  </a></div>
              </div>
              <div className="section-cta__btn"><a href="/enquiry" className="uk-button uk-button-danger uk-button-large"><span>Demander un devis</span><img
                  src="/assets/img/icons/arrow.svg" alt="arrow" data-uk-svg={true}/></a></div>
          </div>
      </div>
  </div>
}

export default HomeCtaSection;