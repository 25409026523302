import React from "react";

const CategoryViewer = ({category}:{category: any}) => {

    return <>
        <div>
            <div className="category-item"><a
                className="category-item__link uk-inline-clip uk-transition-toggle" href={`/equipments?cat=${category.slug}`}>
                <div className="category-item__media"><img src={category.illustration} alt={category.name}/>
                    <div className="uk-transition-fade">
                        <div className="uk-overlay-primary uk-position-cover"></div>
                        <div className="uk-position-center"><span
                            data-uk-icon="icon: plus; ratio: 2"></span></div>
                    </div>
                </div>
                <div className="category-item__title"><span title={category.name} style={{width: '330px',whiteSpace: 'nowrap', textAlign:'center', overflow: 'hidden', textOverflow: 'ellipsis'}}>{category.name}</span></div>
            </a></div>
        </div>
    </>
}

export default CategoryViewer;