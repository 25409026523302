import React from "react";

const processPages = (length: number) => {
    if (!length || length === 1)
        return [];
    let pages: number[] = [];
    for (let i=1; i < length; i++)
        pages.push(i);
    return  pages;
}
const Pagination = ({page, pageTotal, onChange}:{page:number, pageTotal: number, onChange: (page: number) => void}) => {
  return <ul className="uk-pagination uk-margin-large-top">
      <li style={{cursor:'pointer'}} onClick={() => {onChange(1)}} className={(page === 1)?"uk-active":''}><span >1</span></li>
      {
          processPages(pageTotal).map(p => (<li  onClick={() => {onChange((p+1))}} style={{cursor:'pointer'}} key={`page${p}`} className={(page === (p + 1))?"uk-active":''}>
              <span>{(p + 1)}</span></li>))
      }
      <li onClick={() => {
          if (page < pageTotal)
              onChange((page+1));
      }} style={{cursor:'pointer'}}>
          <span className={(page < pageTotal)?"uk-pagination-next":"uk-pagination-next uk-disabled"} ><img src="/assets/img/icons/arrow.svg" alt="" data-uk-svg="" /></span>
      </li>
  </ul>
}

export default Pagination;