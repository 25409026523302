import React, {useEffect} from "react";
import {loadScript} from "../util/helper";
import Layout from "../components/layout/Layout";
import EquipmentCategoryImageLister from "../components/equipments/equipment-category-image-lister";


const EquipmentCategories = () => {


    useEffect(() => {
        loadScript("/assets/js/libs.js");
        setTimeout(() => {
            loadScript("/assets/js/main.js");
        })
    })
    const getCategories = () => {

    }
    return <>
        <Layout title={'Contact'}>
            <EquipmentCategoryImageLister/>
        </Layout>
    </>
}

export default EquipmentCategories;