import React from "react";

const CategoryEmpty = () => {
  return <div className="uk-text-center">
      <div uk-alert={"true"} className="uk-margin uk-background-muted uk-card uk-card-default uk-card-body">
          <h3>Aucune categorie trouvée !</h3>
          <p>Il semblerait qu'il n'y a aucune categorie disponible en ce moment. Revenez régulièrement sur cette page pour trouver vos categories préférées.</p>
      </div>

  </div>
}

export default CategoryEmpty;