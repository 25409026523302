import React, {useState} from "react";
import HomeCtaSection from "../home/home-cta-section";
import useSWR from "swr";
import {appUrls} from "../../util/api_constant";
import {post, postWithParams} from "../../services/BaseService";
import CategoryViewer from "./category-viewer";
import Loader from "./loader";
import CategoryEmpty from "./category-empty";
import {processParam} from "../shared";
import Pagination from "./pagination";
import SearchComponent from "../shared/search-component";
const EquipmentCategoryImageLister = () => {
    const [params, _] = useState<any>(processParam({defaultValue:`group=all`}));
    const {data, error} = useSWR<{ page: number, pageTotal: number, data: any[] }>(`${appUrls.categories}?size=12`, url => postWithParams(url, params, {}));
    const {data:groups} = useSWR<{data: any[]}>(`${appUrls.specifications}?type=GROUP`, url => post(url, {}));

    const onPageChange = (page: number) => {
        processParam({currentValue: (params && params.group)?`?group=${params.group}${params.query?`&query=${params.query}`:''}&page=${page}`:`?page=${page}`, defaultValue:`group=all`})
    }

    const onFilter = (value: string) => {
        processParam({currentValue: (params && params.group)?`?group=${params.group}&query=${value}&page=1`:`?page=1`, defaultValue:`group=all`})
    }
    return <>
        <div className="page-head">
            <div className="page-head__bg" style={{backgroundImage: "url(/assets/img/bg/bg_categories.jpg)"}}>
                <div className="page-head__content" data-uk-parallax="y: 0, 100">
                    <div className="uk-container">
                        <div className="header-icons"><span></span><span></span><span></span></div>
                        <div className="page-head__title">Categories</div>
                        <div className="page-head__breadcrumb">
                            <ul className="uk-breadcrumb">
                                <li><a href="/">Home</a></li>
                                <li><span>Categories</span></li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>

        </div>

        <div className="page-content">
            <div style={{width:300}} className="uk-align-center uk-margin-right uk-margin-top">
                <SearchComponent currentValue={params.query} onChange={onFilter} inputClassName="uk-input uk-form-controls"/>
            </div>
            <div className="uk-section-large uk-container">
                <ul className="uk-subnav uk-subnav-pill">
                    <li className={(!params || params.group === "all")?"uk-active":''}><a href={`/equipment-categories?group=all${params.query?`&query=${params.query}`:''}&page=1`}>TOUTES LES CATÉGORIES</a></li>
                    {
                        (groups && groups.data && groups.data.length > 0)?
                            groups.data.map(g => (<li key={`g_${g.value}`} className={(params && params.group === g.value)?"uk-active":''}><a href={`/equipment-categories?group=${g.value}${params.query?`&query=${params.query}`:''}&page=1`}>{g.label}</a></li>))
                            :null
                    }
                </ul>
                {
                    (!data && !error)?
                        <Loader/> :
                        (data && data.data && data.data.length > 0)?
                    <div className="uk-grid uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s" data-uk-grid="true">
                        {
                            data.data.map((d: any) => (<CategoryViewer key={`cat_all_${d.id}`} category={d}/>))
                        }
                    </div> : <CategoryEmpty/>
                }
                {
                    (data && data.data && data.data.length > 0)?
                        <div><Pagination page={(params && params.page)?+params.page:1} pageTotal={data.pageTotal} onChange={onPageChange}/></div> : null
                }
            </div>
        </div>
        <HomeCtaSection/>
    </>
}

export default EquipmentCategoryImageLister;